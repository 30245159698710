@charset "UTF-8";
img { height: auto; max-width: 100%; }

form#group-search { border: 0; width: 144px; margin: 0 auto; position: relative; }
@media (min-width: 64.5em) { form#group-search { float: left; } }

form#group-search fieldset { position: relative; border: 0; width: 138px; padding: 5px; background: #fff; border: 3px solid #228fcb; overflow: hidden; }

form#group-search #group-search-term { float: left; width: 94px; height: 30px; line-height: 26px; border: 0; padding: 0; font-size: 1em; font-family: "proxima-nova-1", "proxima-nova-2", sans-serif; color: #228fcb; font-weight: 700; background: none; }

form#group-search .placeholder { color: #228fcb; opacity: 1; }
form#group-search ::-webkit-input-placeholder { color: #228fcb; }
form#group-search :-moz-placeholder { color: #228fcb; opacity: 1; }
form#group-search ::-moz-placeholder { color: #228fcb; opacity: 1; }
form#group-search :-ms-input-placeholder { color: #228fcb; }

form#group-search #group-search-submit { float: right; font-size: 20px; width: 26px; height: 21px; margin-top: 6px; border: 0; outline: none; display: block; background: none; color: #228fcb; cursor: pointer; text-align: left; }
form#group-search #group-search-submit:hover { color: #23343b; }

form#group-search.start #group-search-submit { opacity: 0; }

.filters { margin-bottom: 3em; padding: 3em 5% 0 5%; border-top: 1px solid rgba(0, 0, 0, 0.15); }
@media (min-width: 48em) { .filters { padding: 3em 0 0 0; } }
.filters h6 { display: none; }
@media (min-width: 64.5em) { .filters h6 { display: block; float: left; margin-top: .75em; margin-right: 1em; font-size: 1em; color: #228fcb; } }

#featured-story { margin-bottom: 3em; padding: 3em 5% 0 5%; border-top: 1px solid rgba(0, 0, 0, 0.15); overflow: hidden; }
@media (min-width: 64.5em) { #featured-story { padding: 3em 0; } }
#featured-story .image { margin-bottom: 1em; position: relative; }
@media (min-width: 48em) { #featured-story .image { float: left; width: 47.5%; margin: 0; } }
#featured-story .image a { display: block; position: relative; font-size: 1.5em; }
#featured-story .image a:after { content: ""; position: absolute; right: .5em; bottom: .5em; padding: .35em .35em .25em .35em; color: #228fcb; background-color: #fff; vertical-align: middle; z-index: 50; }
#featured-story .image img { display: block; width: 100%; }
@media (min-width: 48em) { #featured-story .meta { float: right; width: 47.5%; } }
#featured-story .meta h6 { font-size: 1.125em; text-transform: uppercase; }
#featured-story .meta h2 { margin-bottom: .25em; }
#featured-story .meta .more { margin: 0; }

#featured-story.written-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }

#featured-story.audio-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }

#featured-story.video-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }

#featured-story.photo-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }

#stories { background: none; min-height: 0; padding: 0 5%; }
@media (min-width: 48em) { #stories { padding: 0; } }
#stories.loading { min-height: 15em; background: url(/_assets/img/ajax-loader.gif) no-repeat center center; }
#stories article { margin-bottom: 2em; }
@media (min-width: 48em) { #stories article { float: left; width: 32%; margin: 0 0 2em 2%; }
  #stories article:first-child { margin-left: 0; } }
#stories article .image { margin-bottom: 1em; position: relative; }
#stories article .image a { display: block; position: relative; font-size: 1.5em; }
#stories article .image a:after { content: ""; position: absolute; right: .5em; bottom: .5em; padding: .35em .35em .25em .35em; color: #228fcb; background-color: #fff; vertical-align: middle; z-index: 50; }
#stories article .image img { display: block; width: 100%; }
#stories article h5 { text-transform: none; }
#stories article.written-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }
#stories article.audio-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }
#stories article.video-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }
#stories article.photo-stories .image a:after { content: ""; font-family: "The-Village"; font-size: 100%; font-weight: normal !important; vertical-align: middle; }
